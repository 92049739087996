import * as React from 'react'
import { graphql, useStaticQuery, Link as GLink } from 'gatsby'

const REGEX_FILE_NAME = /.*\/(.*)\.md/

function Link({
    to,
    title,
    indented,
    className = '',
}: {
    to: string
    title: string
    indented?: boolean
    className?: string
}) {
    return (
        <div className={`${indented ? 'ml-4' : ''} ${className}`}>
            <GLink
                className={`flex flex-1 -indent-2 ml-2 pl-4 pr-2 py-1 text hover:bg-tag hover:dark:bg-tag-dark transition duration-150 rounded ${
                    indented ? 'ml-4' : ''
                }`}
                activeClassName="text-accent dark:text-accent-dark bg-tag dark:bg-tag-dark transition !duration-500"
                to={to}
            >
                {title}
            </GLink>
        </div>
    )
}

function EdgesToLinks({
    edges,
    title,
    slug,
}: {
    edges: any
    title: string
    slug: string
}) {
    const renderEdge = React.useCallback(
        ({ node }) => {
            const { frontmatter, fileAbsolutePath, headings } = node
            const { title: frontMatterTitle } = frontmatter

            const fileNameMatch = fileAbsolutePath.match(REGEX_FILE_NAME)

            const to = `${slug}/${fileNameMatch[1]}`

            const title = frontMatterTitle || headings[0]?.value || ''

            return <Link to={to} title={title} />
        },
        [slug],
    )

    return (
        <div className="flex flex-col">
            <Link
                to={slug}
                title={title}
                className="mt-8 text-lg font-bold"
            />
            {edges.map(renderEdge)}
        </div>
    )
}

export default function SidebarNavigation() {
    const result = useStaticQuery(
        graphql`
            query {
                chapters: allMarkdownRemark(
                    sort: { fields: frontmatter___sidebar_position, order: ASC }
                    filter: { fileAbsolutePath: { regex: "/chapters/" } }
                ) {
                    edges {
                        node {
                            fileAbsolutePath
                            frontmatter {
                                title
                                sidebar_position
                            }
                            headings(depth: h1) {
                                id
                                value
                            }
                        }
                    }
                }
                languages: allMarkdownRemark(
                    sort: { fields: frontmatter___sidebar_position, order: ASC }
                    filter: { fileAbsolutePath: { regex: "/languages/" } }
                ) {
                    edges {
                        node {
                            fileAbsolutePath
                            frontmatter {
                                title
                                sidebar_position
                            }
                            id
                            headings(depth: h1) {
                                id
                                value
                            }
                        }
                    }
                }
                recommendedReading: allMarkdownRemark(
                    sort: { fields: frontmatter___sidebar_position, order: ASC }
                    filter: {
                        fileAbsolutePath: { regex: "/recommended-reading/" }
                    }
                ) {
                    edges {
                        node {
                            fileAbsolutePath
                            frontmatter {
                                title
                                sidebar_position
                            }
                            id
                            headings(depth: h1) {
                                id
                                value
                            }
                        }
                    }
                }
                reference: allMarkdownRemark(
                    sort: { fields: frontmatter___sidebar_position, order: ASC }
                    filter: { fileAbsolutePath: { regex: "/reference/" } }
                ) {
                    edges {
                        node {
                            fileAbsolutePath
                            frontmatter {
                                title
                                sidebar_position
                            }
                            id
                            headings(depth: h1) {
                                id
                                value
                            }
                        }
                    }
                }
            }
        `,
    )
    const { chapters, languages, recommendedReading, reference } = result

    return (
        <div className="sticky top-6 hidden md:flex flex-col mt-6 mr-6 py-2 min-w-[10rem] lg:min-w-[14rem] max-w-[16rem]">
            <Link
                to="/"
                title="Intro"
                className="text-lg font-bold"
            />
            <EdgesToLinks
                edges={chapters.edges}
                title="Chapters"
                slug="/chapters"
            />
            {/* <EdgesToLinks
                edges={reference.edges}
                title="Reference"
                slug="/reference"
            />
            <EdgesToLinks
                edges={languages.edges}
                title="Languages"
                slug="/languages"
            /> */}
            {/* <EdgesToLinks
                edges={recommendedReading.edges}
                title="Reading"
                slug="/recommended-reading"
            /> */}
        </div>
    )
}
