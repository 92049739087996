import * as React from 'react'
import { Link } from 'gatsby'
import BasePage from './BasePage'

type Heading = {
    id: string
    value: string
    depth: number
}

export type PageData = {
    frontMatter: {
        title: string
        slug: string
        tags: string[]
        description: string
        date: string
        lastUpdated: string | null
    }
    timeToRead: number
    html: string
    titleHeadings: Heading[]
    headings: Heading[]
}

type Props = {
    data: {
        page: PageData
    }
}

export default function GenericPageWithToc({ data }: Props) {
    const { page } = data
    const { frontMatter, html, headings, titleHeadings } = page
    const { title: frontMatterTitle, lastUpdated } = frontMatter

    const title = frontMatterTitle || titleHeadings[0]?.value || ''

    console.log({ titleHeadings })

    const renderHeading = React.useCallback((heading: Heading) => {
        if (heading.depth !== 2 && heading.depth !== 3) {
            return null
        }
        return (
            <Link
                to={`#${heading.value.toLowerCase().replace(/ /g, '-')}`}
                className={`mb-1 text hover:text-accent hover:dark:text-accent-dark no-underline -indent-4 ${
                    heading.depth > 2 ? 'ml-8' : 'ml-4'
                }`}
                key={heading.id}
            >
                {heading.value}
            </Link>
        )
    }, [])

    return (
        <BasePage title={title}>
            <div className="flex flex-col my-2 md:ml-2 lg:mx-4">
                <div className="mt-3 mb-2">
                    <h1 className='mb-0'>{title}</h1>
                    {lastUpdated && (
                        <p className="secondary">
                            Last Updated
                            {': '}
                            <time dateTime={lastUpdated}>{lastUpdated}</time>
                        </p>
                    )}
                </div>
                <article className='content width-control' dangerouslySetInnerHTML={{ __html: html }} />
            </div>
            <div className="mt-8 ml-4 hidden lg:flex">
                <nav className="sticky top-8 flex flex-col min-w-[12rem]">
                    <h2 className='mb-10'>Contents</h2>
                    {headings.map(renderHeading)}
                </nav>
            </div>
        </BasePage>
    )
}
