import * as React from 'react'
import { Link } from 'gatsby'

export default function HeaderBar() {
    React.useEffect(() => {
        if (typeof window !== 'undefined' && window.document) {
            const bodyClassList = document.body.classList

            const htmlClassList = document.querySelector('html')?.classList
            const toggleButton = document.querySelector('.toggle-button')
            const systemDarkSetting = window.matchMedia(
                '(prefers-color-scheme: dark)',
            )
            const storeDarkValue = localStorage.getItem('dark')

            const setDark = (isDark) => {
                if (htmlClassList) {
                    htmlClassList[isDark ? 'add' : 'remove']('dark')
                    localStorage.setItem('dark', isDark ? 'yes' : 'no')
                }
            }

            setDark(
                storeDarkValue
                    ? storeDarkValue === 'yes'
                    : systemDarkSetting.matches,
            )
            requestAnimationFrame(() => bodyClassList.remove('hidden'))

            if (toggleButton) {
                toggleButton.addEventListener('click', () => {
                    if (htmlClassList) {
                        setDark(!htmlClassList.contains('dark'))
                    }
                })
            }
            systemDarkSetting.addEventListener('change', (event) =>
                setDark(event.matches),
            )
        }
    }, [])

    return (
        <header className="flex w-full justify-between items-center py-4 md:pl-4">
            <Link to="/" className="dark:text-white text-xl">
                ProgrammingWithZoe
            </Link>
            <div className="toggle-button ml-4">
                <svg
                    className="toggle-button cursor-pointer text-middleGray rotate-180 dark:rotate-0 transition-all duration-500"
                    width="24"
                    height="24"
                    viewBox="0 0 100 100"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                >
                    <path
                        className="fill-current"
                        d="M 50 0 A 1 1 0 0 0 50 100"
                    />
                    <circle
                        cx="50"
                        cy="50"
                        r="44"
                        className="stroke-current"
                        stroke-width="8"
                    />
                </svg>
            </div>
        </header>
    )
}
